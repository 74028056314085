import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
  constructor(
    private _permissionCheckerService: PermissionCheckerService,
    private _appSessionService: AppSessionService
  ) { }

  //   STUDENT SIDE NAVIGATIONS
  getMenu(): AppMenu {
    //   ADMIN SIDE NAVIGATIONS
    return new AppMenu('MainMenu', 'MainMenu', [
      new AppMenuItem(
        'Dashboard',
        'Pages.Administration.Host.Dashboard',
        'fa-duotone fa-house',
        '/app/admin/hostDashboard'
      ),
      new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'fa-duotone fa-house', '/app/main/dashboard'),
      new AppMenuItem('Tenants', 'Pages.Tenants', 'pi pi-users', '/app/admin/tenants'),

      //new AppMenuItem('ClassGeoLocation', 'Pages.ClassGeoLocation', 'flaticon-more', '/app/main/student/classGeoLocation'),

      // new AppMenuItem('GpsAttendances', 'Pages.GpsAttendances', 'flaticon-more', '/app/main/student/gpsAttendances'),

      
      new AppMenuItem('Editions', 'Pages.Editions', 'pi pi-book', '/app/admin/editions'),



      new AppMenuItem('Advertisement', 'Pages.Advertisements', 'fa-duotone fa-newspaper', '/app/main/patro/Advertisements'),

      // rashifal

      new AppMenuItem(
        'Rashifal',
        '',
        'fas fa-star',
        '',
        [],
        [
          new AppMenuItem('Tithi ', 'Pages.Advertisements', 'fa-duotone fa-newspaper', '/app/main/rashifal/tithi'),
          new AppMenuItem('Daily Rashifal', 'Pages.Advertisements', 'fa-duotone fa-square-list', '/app/main/rashifal/daily-rashifal'),
          new AppMenuItem('Weekly Rashifal', 'Pages.Advertisements', 'fa-duotone fa-calendar-lines', '/app/main/rashifal/weekly-rashifal'),
          new AppMenuItem('Monthly Rashifal', 'Pages.Advertisements', 'fa-duotone fa-calendar-lines', '/app/main/rashifal/monthly-rashifal'),
         new AppMenuItem('Yearly Rashifal', 'Pages.Advertisements', 'fa-duotone fa-calendar-lines', '/app/main/rashifal/yearly-rashifal'),

        ]
      ),
      new AppMenuItem(
        'Temple',
        '',
        'fa-duotone fa-synagogue',
        '',
        [],
        [
          new AppMenuItem('Astrologer', 'Pages.Advertisements', 'fa-duotone fa-newspaper', '/app/main/temple/astrologer'),
          new AppMenuItem('Audio Category ', 'Pages.Advertisements', 'fa-duotone fa-newspaper', '/app/main/temple/audio-category'),
          new AppMenuItem('Audio', 'Pages.Advertisements', 'fa-duotone fa-newspaper', '/app/main/temple/audio-temple'),
          new AppMenuItem('Book Category ', 'Pages.Advertisements', 'fa-duotone fa-newspaper', '/app/main/temple/book-category'),
          new AppMenuItem('Book', 'Pages.Advertisements', 'fa-duotone fa-newspaper', '/app/main/temple/book-temple'),
          // new AppMenuItem('Day Details ', 'Pages.Advertisements', 'fa-duotone fa-newspaper', '/app/main/temple/day-details'),
          new AppMenuItem('Event ', 'Pages.Advertisements', 'fa-duotone fa-newspaper', '/app/main/temple/event-temple'),
          new AppMenuItem('Program ', 'Pages.Advertisements', 'fa-duotone fa-newspaper', '/app/main/temple/program-temple'),
          new AppMenuItem('Site Type ', 'Pages.Advertisements', 'fa-duotone fa-newspaper', '/app/main/temple/siteType'),
          new AppMenuItem('Site ', 'Pages.Advertisements', 'fa-duotone fa-newspaper', '/app/main/temple/site'),
          new AppMenuItem('Temple ', 'Pages.Advertisements', 'fa-duotone fa-newspaper', '/app/main/temple/temple'),
          new AppMenuItem('Mantra ', 'Pages.Advertisements', 'fa-duotone fa-newspaper', '/app/main/temple/mantra'),

        ]
      ),



      new AppMenuItem('Notice', 'Pages.Notices', 'fa-duotone fa-bell-exclamation', '/app/main/patro/Notices'),

      new AppMenuItem('Pages', 'Pages.PatroPages', 'fa-duotone fa-page', '/app/main/patro/page'),
      new AppMenuItem(
        'Banner Categories',
        'Pages.BannerCategories',
        'fa-duotone fa-layer-group',
        '/app/main/patro/bannercategory'
      ),
      new AppMenuItem('Banners', 'Pages.Banners', 'fa-duotone fa-ribbon', '/app/main/patro/banner'),
      new AppMenuItem(
        'Blog Categories',
        'Pages.BlogCategories',
        'fa-duotone fa-layer-group',
        '/app/main/patro/blogcategory'
      ),
      new AppMenuItem(
        'Book Categories',
        'Pages.BlogCategories',
        'fa-duotone fa-layer-group',
        '/app/main/patro/bookcategory'
      ),
      new AppMenuItem('Blogs', 'Pages.Blogs', 'fa-duotone fa-blog', '/app/main/patro/blog'),
      new AppMenuItem('Events', 'Pages.PatroEvents', 'fa-duotone fa-calendar', '/app/main/patro/event'),
      new AppMenuItem(
        'Facility',
        'Pages.PatroFacilities',
        'fa-duotone fa-house-medical',
        '/app/main/patro/Facility'
      ),
      new AppMenuItem(
        'Download',
        'Pages.PatroDownloads',
        'fa-duotone fa-circle-down',
        '/app/main/patro/Download'
      ),
      new AppMenuItem(
        'Gallery Categories',
        'Pages.GalleryCategories',
        'fa-duotone fa-image-polaroid',
        '/app/main/patro/gallerycategories'
      ),
      new AppMenuItem('Gallery', 'Pages.Gallery', 'fa-duotone fa-image', '/app/main/patro/gallery'),
      new AppMenuItem('TeamMembers', 'Pages.TeamMembers', 'fa-duotone fa-users', '/app/main/patro/teammember'),
      new AppMenuItem('Courses', 'Pages.PatroCourse', 'fa-duotone fa-books', '/app/main/patro/course'),
      new AppMenuItem('Careers', 'Pages.PatroCourse', 'fa-duotone fa-vector-circle', '/app/main/patro/careers'),
      new AppMenuItem('Video Categories', '', 'fa-duotone fa-speaker', '/app/main/patro/PatroVideoCategories'),
      new AppMenuItem('Video', 'Pages.PatroVideos', 'fa-duotone fa-speaker', '/app/main/patro/PatroVideo'),
      new AppMenuItem(
        'Testimonials',
        'Pages.PatroCourse',
        'fa-duotone fa-speaker',
        '/app/main/patro/testimonials'
      ),


      new AppMenuItem(
        'Setting',
        '',
        'fa-duotone fa-gear',
        '',
        [],
        [
          new AppMenuItem('Company', 'Pages.Branchs', 'fa-duotone fa-buildings', '/app/main/controlPanel/branchs'),
          new AppMenuItem('Address', 'Pages.Branchs', 'fa-duotone fa-location-dot', '/app/main/controlPanel/address'),
          new AppMenuItem(
            'Notifications',
            '',
            'flaticon-alarm',
            '',
            [],
            [
              new AppMenuItem(
                'Inbox',
                '',
                'flaticon-mail-1',
                '/app/notifications'
              ),
              new AppMenuItem(
                'MassNotifications',
                'Pages.Administration.MassNotification',
                'flaticon-paper-plane',
                '/app/admin/mass-notifications'
              ),
            ]
          ),
          new AppMenuItem(
            'OrganizationUnits',
            'Pages.Administration.OrganizationUnits',
            'fa-duotone fa-house-blank',
            '/app/admin/organization-units'
          ),
          new AppMenuItem('Roles', 'Pages.Administration.Roles', 'fa-duotone fa-person', '/app/admin/roles'),
          new AppMenuItem('Users', 'Pages.Administration.Users', 'fa-duotone fa-users', '/app/admin/users'),
          new AppMenuItem(
            'Languages',
            'Pages.Administration.Languages',
            'fa-duotone fa-language',
            '/app/admin/languages',
            ['/app/admin/languages/{name}/texts']
          ),
          new AppMenuItem(
            'AuditLogs',
            'Pages.Administration.AuditLogs',
            'fa-duotone fa-list-check',
            '/app/admin/auditLogs'
          ),
          new AppMenuItem(
            'Maintenance',
            'Pages.Administration.Host.Maintenance',
            'fa-duotone fa-sliders',
            '/app/admin/maintenance'
          ),
          new AppMenuItem(
            'Subscription',
            'Pages.Administration.Tenant.SubscriptionManagement',
            'fa-duotone fa-hand-pointer',
            '/app/admin/subscription-management'
          ),
          new AppMenuItem(
            'VisualSettings',
            'Pages.Administration.UiCustomization',
            'fa-duotone fa-eye',
            '/app/admin/ui-customization'
          ),
          new AppMenuItem(
            'WebhookSubscriptions',
            'Pages.Administration.WebhookSubscription',
            'fa-duotone fa-bell',
            '/app/admin/webhook-subscriptions'
          ),
          new AppMenuItem(
            'DynamicProperties',
            'Pages.Administration.DynamicProperties',
            'fa-duotone fa-star',
            '/app/admin/dynamic-property'
          ),
          new AppMenuItem(
            'Settings',
            'Pages.Administration.Host.Settings',
            'fa-duotone fa-gear',
            '/app/admin/hostSettings'
          ),
          new AppMenuItem(
            'Settings',
            'Pages.Administration.Tenant.Settings',
            'fa-duotone fa-gear',
            '/app/admin/tenantSettings'
          ),
        ]
      ),
    ]);
  }


  checkChildMenuItemPermission(menuItem): boolean {
    for (let i = 0; i < menuItem.items.length; i++) {
      let subMenuItem = menuItem.items[i];

      if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
        if (subMenuItem.route) {
          return true;
        }
      } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
        return true;
      }

      if (subMenuItem.items && subMenuItem.items.length) {
        let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
        if (isAnyChildItemActive) {
          return true;
        }
      }
    }
    return false;
  }

  showMenuItem(menuItem: AppMenuItem): boolean {
    if (
      menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
      this._appSessionService.tenant &&
      !this._appSessionService.tenant.edition
    ) {
      return false;
    }

    let hideMenuItem = false;

    if (menuItem.requiresAuthentication && !this._appSessionService.user) {
      hideMenuItem = true;
    }

    if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
      hideMenuItem = true;
    }

    if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
      if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
        hideMenuItem = true;
      }
    }

    if (!hideMenuItem && menuItem.items && menuItem.items.length) {
      return this.checkChildMenuItemPermission(menuItem);
    }

    return !hideMenuItem;
  }

  /**
   * Returns all menu items recursively
   */
  getAllMenuItems(): AppMenuItem[] {
    let menu = this.getMenu();
    let allMenuItems: AppMenuItem[] = [];
    menu.items.forEach((menuItem) => {
      allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
    });

    return allMenuItems;
  }

  private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
    if (!menuItem.items) {
      return [menuItem];
    }

    let menuItems = [menuItem];
    menuItem.items.forEach((subMenu) => {
      menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
    });

    return menuItems;
  }
}
